export default {
  homeHeader: `Ouvrir le catalogue`,
  title: `Notre offre de formations`,
  intro: `Voici notre offre actualisée afin de répondre à l’évolution continue de vos besoins de formations.`,
  instructions: ``,
  // title: `AFI vous supporte dans votre virage virtuel`,
  // intro: `En vous inscrivant avant le 30 avril 2020, vous profitez d’un rabais de 40% sur toutes nos sessions garanties en classe virtuelle au calendrier 2020.`,
  // instructions: `Saisir le code promotionnel <strong>VIRT40</strong> dans la case présentée lorsque vous sélectionnez votre session garantie [symbole] en classe virtuelle.`,

  orderBy: {
    byRanking: `Par popularité`,
    byIsNew: `Par nouveautés`,
  },

  byKeywords: `Par mot-clé`,
  nbHits: `{{count}} correspondance`,
  nbHits_plural: `{{count}} correspondances`,

  noResult: `Aucun résultat ne correspond à votre recherche.`,
  noResultTryEliminateFilter: `Tentez de retirer un filtre ci-dessus.`,

  refinement: {
    showLess: `Voir moins`,
    showMore: `Voir plus`,
    noResult: `Aucun item`,
    searchInCategories: `Trouver par mots-clés…`,
    clearThemes: `Effacer les filtres Thématiques`,
    clearEditors: `Effacer les filtres Éditeurs`,
    addFilter: `Affiner les résultats parmi les sessions au calendrier`,
    addFilterCompact: `Affiner parmi les sessions au calendrier`,
    otherCategories: `Catégories`,

    items: {
      sessions: `Sessions au calendrier :`,
      confirmed: `avec sessions garanties`,
      SessionsConfirmed: `avec sessions garanties`,
      virtual: `en classe virtuelle`,
      // eslint-disable-next-line prettier/prettier
      Québec: `à Québec`,
      // eslint-disable-next-line prettier/prettier
      Montréal: `à Montréal`,
      Laval: `à Laval`,
      fr: `en français`,
      en: `en anglais`,
    },

    byTheme: `Thématiques`,
    byEditors: `Éditeurs`,
    byAccreditations: `Accréditations professionnelles`,
    byCity: `Offerte à`,
    byLanguage: `Diffusée en`,
    filtersInstructions: `Sélectionnez plusieurs filtres si nécessaire. Maximum d’un choix par groupe.`,
  },

  downloadCatalog: {
    title: `Téléchargez le<br />catalogue 2024-2025`,
    yourEmail: `Votre adresse courriel ici`,
  },

  features: {
    more: `En savoir plus`,
    private: {
      title: `Formation privée`,
      text: `Besoin d’aligner les compétences de vos équipes avec votre vision d’affaires ? Nous pouvons vous aider.`,
    },
    virtual: {
      title: `Classe virtuelle`,
      text: `Quel que soit votre emplacement, nous offrons la possibilité de suivre votre formation en direct via nos classes virtuelles.`,
    },
    promo: {
      title: `Forfaits et promotion`,
      text: `Laissez-nous vous conseiller sur les différentes options qui s’offre à votre entreprise afin de maximiser votre budget de formation et obtenir un retour sur investissement.`,
    },
  },

  magazine: {
    title: `Magazine Explore`,
    heading: `Téléchargez le magazine Explore`,
    cta: `Téléchargez votre copie`,
    summaryHeading: `Dans cette édition`,
    description: `Télécharge le magazine Explore 2023 et obtiens 20%* de rabais sur ta prochaine formation.`,
    terms: `*Certaine <a target="_blank" href="https://www.afiexpertise.com/fr/a-propos/faq">conditions</a> s’appliquent.`,
    summary: `
<li>Découvrez 13 humains d’impact et les formations qu’ils vous recommandent</li>
<li>Explorez nos programmes de développement du leadership</li>
<li>
Consultez la liste des certifications TI les plus en demande pour 2023
</li>
<li>
Apprenez par le biais d’entrevues avec des experts de la famille Edgenda
<ul class="list-disc pl-4 space-y-2">
<li>Nathalie Gosselin, Chef de la direction stratégies et services-conseils, Edgenda</li>
<li>Julie Castonguay, Chef de l’expérience client, AFI par Edgenda</li>
<li>Pierre-Alexandre Bradette, Chef des opérations, Apprentx par Edgenda</li>
</ul>
</li>
<li>Consultez notre catalogue de formation et les nouveautés de la rentrée.</li>`,
  },
  coloredTitle: {
    firstPart: 'Formations',
    firstPartIn: 'Formations en',
    secondPart: 'à la carte',
  },
  human: 'Capital humain',
  offerParagraph:
    "Voici notre offre actualisée afin de répondre à l'évolution continue de vos besoins de formations.",
  seeOnlyNewItems: `voir seulement les nouveautés`,
  news: `Nouveautés`,
  'training-security': 'Formations en infrastructure, système et sécurité',
  securityAndInfrastructure: `sécurité d'infrastructure et système`,
}
